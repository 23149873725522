export const theme = {
  color: {
    primary: "#ff5500",
    primaryRGB: "255,85,0",
    primaryDarken: '#e64d00',
    primaryLight: "#ffede5",
    white: "#ffffff",
    whiteRGB: "255,255,255",
    error: "#bf0428",
    black: "#000",
    text: "#636b72",
    lightGrey: "#f2f4fb",
    softGrey: "#e0e0e0",
    secondaryGrey: "#eee",
    backgroundGrey: "#F9F9F9F",
    backgroundDark: "#141414",
    backgroundDarkRGB: "20,20,20",
    backgroundDarken: "#1a1a1a",
    heroText: "#CBCDCC",
    shadow: "rgba(61,82,96,0.15)",
    shadowHover: "rgba(61,82,96,0.2)"
  },
  weight: {
    regular: 400,
    robotoSemiBold: 500,
    openSansSemiBold: 600,
    bold: 700
  },
  fonts: {
    heading: "\"Open Sans\", sans-serif",
    text: "\"Roboto\", sans-serif",
    special: "\"Stardos Stencil\", \"Roboto\", \"Open Sans\", sans-serif"
  },
  breakpoints: {
    tabLand: "1200px",
    tabPort: "992px",
    phoneLand: "768px",
    phonePort: "576px",
    xsPhone: "320px"
  }
}