import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const FooterStyled = styled.footer`
  //grid-column: full-start / full-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.customStyle === 'footer-fixed' ? 'width: 100%': ''}
  ${props => props.customStyle === 'footer-fixed' ? 'position: absolute': ''}
  ${props => props.customStyle === 'footer-fixed' ? 'bottom: 0': ''}
`

const FooterMain = styled.div`
  background: ${props => props.theme.color.backgroundDark};
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (${mediaQueries.tabLand}) {
    padding: 30px 0;
  }

  @media only screen and (${mediaQueries.phoneLand}) {
    padding: 20px 0;
  }
`

const Copyright = styled.div`
  background: ${props => props.theme.color.backgroundDarken};
  padding: 30px 0;
`

const CopyrightText = styled.p`
  color: ${props => props.theme.color.white};
  text-align: center;
  opacity: 0.7;
`

const FooterSocialStyled = styled.div`
  margin-top: 10px;
`

const FooterSocialItem = styled.li`
  display: inline-block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`

const FooterTerms = styled.p`
  text-align: center;
  color: rgba(${props => props.theme.color.whiteRGB}, 0.7);
`

const FooterAnnexe = styled.ul`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(${props => props.theme.color.white}, 0.7);

  li {
    padding: 0rem 2rem;
  }

  li:not(:first-child){
    border-left: solid 1px rgba(212,212,255,0.6);
  }
`

const FooterSocialLink = styled.a`
  height: 56px;
  width: 56px;
  color: ${props => props.theme.color.white};
  line-height: 56px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  background: ${props => props.theme.color.backgroundDarken};
  transition: all 0.25s linear;

  &:hover {
    background: ${props => props.theme.color.primary};
    animation: pulseOrange 1s 1 linear;
    transform: translateY(-5px);
  }
`

const PrimaryLink = styled.a`
  color: rgba(${props => props.theme.color.primaryRGB}, 0.7);
  font-family: ${props => props.theme.fonts.text};
  transition: all .2S;

  &:hover {
    color: ${props => props.theme.color.primary};
  }
`

const Footer = ({ footerStyle = "" }) => {
  const logo = useStaticQuery(graphql`
    query {
        logoWhite: file(relativePath: { eq: "logo-teicno-white.png" }) {
            childImageSharp {
                fixed(width: 75) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
  `)

  return (
    <FooterStyled customStyle={footerStyle}>
      <FooterMain>
        <Link to={"/"} aria-label="Logo Teicno">
          <Img fixed={logo.logoWhite.childImageSharp.fixed} alt="Logo" />
        </Link>
        <FooterSocialStyled>
          <FooterSocialItem>
            <FooterSocialLink href="https://www.linkedin.com/in/s%C3%A9bastien-roueche-5375ba5a/" target="_blank" rel="noreferrer" aria-label="Linkedin">
              <FontAwesomeIcon icon={faLinkedin} />
            </FooterSocialLink>
          </FooterSocialItem>
          <FooterSocialItem>
            <FooterSocialLink href="https://twitter.com/s_roueche" target="_blank" rel="noreferrer" aria-label="Twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </FooterSocialLink>
          </FooterSocialItem>
        </FooterSocialStyled>
        <FooterAnnexe>
          <li><PrimaryLink href="/mentions-legales">Mentions légales</PrimaryLink></li>
          {/*<li><a href="/CGV" className="primary-link">CGV</a></li>*/}
        </FooterAnnexe>
        <FooterTerms>This site is protected by reCAPTCHA and the Google
          <PrimaryLink href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"> Privacy Policy </PrimaryLink> and
          <PrimaryLink href="https://policies.google.com/terms" target="_blank" rel="noreferrer"> Terms of Service </PrimaryLink> apply.
        </FooterTerms>
      </FooterMain>
      <Copyright>
        <CopyrightText>© {new Date().getFullYear()}<span id="fullYearCopyright"></span> Sébastien Roueche
        </CopyrightText>
      </Copyright>
    </FooterStyled>
  )
}

export default Footer