/**
 * Change the top fixed bar style
 */
const scrollEventHandler = () => {
  const scrollYValue = window.scrollY

  if (scrollYValue > 100) {
    document.getElementById("header").classList.add("header-shrink")
    if(document.getElementById("scroll-top-btn") !== null){
      document.getElementById("scroll-top-btn").classList.add("active")
    }
  } else {
    document.getElementById("header").classList.remove("header-shrink")
    if(document.getElementById("scroll-top-btn") !== null){
      document.getElementById("scroll-top-btn").classList.remove("active")
    }
  }
}

export default scrollEventHandler