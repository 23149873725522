import { theme } from "./theme"

const {
  tabLand,
  tabPort,
  phoneLand,
  phonePort,
  xsPhone
} = theme.breakpoints

export const mediaQueries = {
  tabLand: `max-width: ${tabLand}`,
  tabPort: `max-width: ${tabPort}`,
  phoneLand: `max-width: ${phoneLand}`,
  phonePort: `max-width: ${phonePort}`,
  xsPhone: `max-width: ${xsPhone}`,
}