import React, { useEffect, useState } from "react"
import { Link as Scroll } from "react-scroll"

const ScrollTo = ({id, target, className, title, offset = 84, children}) => {

  const [headerHeight, setHeaderHeight] = useState(offset);

  useEffect(() => {
    const header = window.document.getElementById("header")
    if(header){
      setHeaderHeight(header.scrollHeight)
    }
  }, [headerHeight])

  const offsetAdded = headerHeight < 80 ? 40 : 90;
  const offsetCalc = offset !== 0 ? ((-1 * headerHeight) - offsetAdded) : 0;

  return <Scroll to={target}
                 id={id}
                 smooth={true}
                 duration={2000}
                 offset={offsetCalc}
                 className={className}
                 title={title ? title : children}>{children}</Scroll>
}

export default ScrollTo