import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

function SEO({ description, lang, meta, title, canonical }) {

  const {t} = useTranslation();
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    image
                    tel
                    twitterUsername
                    url
                    keywords
                    author
                }
            }
        }
    `
  )

  const metaDescription = description || t("seo.description")
  const jsonld = {
    "@context": "https://schema.org",
    "@type": "Person",
    url: site.siteMetadata.url,
    name: "Teicno",
    jobTitle: t("seo.title"),
    contactPoint: {
      "@type": "ContactPoint",
      telephone: site.siteMetadata.tel,
      contactType: "Customer service"
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title="Teicno"
      titleTemplate={`%s | ${title}`}
      link={[
        {
          rel: 'image_src',
          href: site.siteMetadata.url + "/screenshot-teicno.jpg"
        },
        {
          rel: 'canonical',
          href: site.siteMetadata.url + "\\" + canonical
        }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `robots`,
          content: `index,follow`
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords
        },
        {
          name: `author`,
          content: site.siteMetadata.author
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:url`,
          content: site.siteMetadata.url + "\\" + canonical
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: site.siteMetadata.url + "/screenshot-teicno.jpg"
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitterUsername
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  canonical: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string
}

export default SEO
