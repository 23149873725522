import { createGlobalStyle } from "styled-components"
import { mediaQueries } from "./mediaQueries"

const GlobalStyle = createGlobalStyle`
  body, html {
    font-size: 62.5%;
    font-family: ${props => props.theme.fonts.text};
    font-weight: ${props => props.theme.weight.regular};
    color: ${props => props.theme.color.text};
    margin: 0;
    padding: 0;
    line-height: 1.5;

    @media only screen and (${mediaQueries.tabLand}) {
      font-size: 56.25%;
    }
    @media only screen and (${mediaQueries.tabPort}) {
      font-size: 50%;
    }
  }

  ::-moz-selection {
    background: ${props => props.theme.color.black};
    color: ${props => props.theme.color.white};
  }

  ::selection {
    background: ${props => props.theme.color.black};
    color: ${props => props.theme.color.white};
  }

  a:link, a:hover, a:active, a:visited {
    text-decoration: none;
  }

  ul, ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  textarea, textarea:focus, input, input:focus, button, button:focus {
    outline: none;
    resize: inherit;
  }

  .contact-btn {
    border: none !important;
  }

  .grecaptcha-badge {
    //Note that you are not using display: none; under any circumstances! This will disable the spam protection of the reCAPTCHA.
    visibility: hidden;
  }

  /* ---------------------------------------------------------------- */
  /* 02.Typography
  /* ---------------------------------------------------------------- */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fonts.heading};
    font-weight: ${props => props.theme.weight.bold};
    color: ${props => props.theme.color.black};
    margin: 0;
    line-height: 1.5;
  }

  p, a, span, blockquote, q {
    font-size: 1.6rem;
  }

  p {
    line-height: 3rem;
    margin: 0;
  }

  h1, .h1 {
    font-size: 6.4rem;
    @media only screen and (${mediaQueries.tabLand}) {
      font-size: 6rem;
    }
    @media only screen and (${mediaQueries.phoneLand}) {
      font-size: 4.8rem;
    }
    @media only screen and (${mediaQueries.phonePort}) {
      font-size: 4.5rem;
    }
  }

  h2, .h2 {
    font-size: 5.2rem;
    @media only screen and (${mediaQueries.tabLand}) {
      font-size: 4.8rem;
    }
    @media only screen and (${mediaQueries.phoneLand}) {
      font-size: 4.2rem;
    }
    @media only screen and (${mediaQueries.phonePort}) {
      font-size: 4rem;
    }
  }

  h3, .h3 {
    font-size: 4.7rem;
    @media only screen and (${mediaQueries.tabLand}) {
      font-size: 4.3rem;
    }
    @media only screen and (${mediaQueries.phoneLand}) {
      font-size: 3.7rem;
    }
    @media only screen and (${mediaQueries.phonePort}) {
      font-size: 3.5rem;
    }
  }

  .text__primary {
    color: ${props => props.theme.color.primary};
    font-weight: ${props => props.theme.weight.regular};
  }

  .text-strong {
    font-weight: ${props => props.theme.weight.bold};
  }

  .primary-link {
    color: ${props => props.theme.color.primary};
    font-family: ${props => props.theme.fonts.text};
    transition: all .2S;

    &:hover {
      color: rgba(${props => props.theme.color.primaryRGB}, 0.8);
    }
  }

  .primary-content {
    &-title {
      font-size: 3.7rem;
      font-family: ${props => props.theme.fonts.text};
      font-weight: ${props => props.theme.weight.regular};
      position: relative;
      margin: 2rem 0;
      width: fit-content;

    @include respond(phone-land) {
      margin-bottom: 2rem;
    }
    }

    &-title:after {
      content: "";
      width: 100%;
      height: 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.color.primary};
    }

    &-title-lvl2 {
      font-size: 2.7rem;
      font-family: ${props => props.theme.fonts.text};
      font-weight: ${props => props.theme.weight.regular};
      position: relative;
      margin: 1rem 0 0rem 1rem;
    }

    &-text {
      margin-bottom: 1rem;
    }
  }

  /* ---------------------------------------------------------------- */
  /* 04.Buttons
  /* ---------------------------------------------------------------- */
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  .btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    font-size: 1.6rem;
  }

  .default-button, .outline-button {
    appearance: none;
    font-size: 1.6rem;
    font-family: ${props => props.theme.fonts.text};
    line-height: 1.5;
    margin: 0;
    border: none;
    padding: 1.6rem 4rem;
    border-radius: 0.5rem;
    letter-spacing: 0.5px;
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 0.25s linear;
  }

  .default-button {
    background: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.white};

    &:hover {
      background: ${props => props.theme.color.primaryDarken};
      color: ${props => props.theme.color.white};
      animation: pulseOrange 1s 1 linear;
      transform: translateY(-5px);
    }
  }

  .outline-button {
    color: ${props => props.theme.color.primary};
    border: 2px solid transparent;
    padding: 1.4rem 4rem;
    background: ${props => props.theme.color.white};

    &:hover {
      border-color: ${props => props.theme.color.white};
      background: transparent;
      color: ${props => props.theme.color.white};
      animation: pulseWhite 1s 1 linear forwards;
      transform: translateY(-0.5rem);
    }
  }

  /* ---------------------------------------------------------------- */
  /* 05.Animations
  /* ---------------------------------------------------------------- */
  @keyframes stickyMenu {
    0% {
      margin-top: -1.2rem;
      opacity: 0;
    }
    50% {
      margin-top: -6.4rem;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }

  @keyframes pulseOrange {
    0% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.4);
    }
    70% {
      box-shadow: 0 0 0 2rem rgba(${props => props.theme.color.primaryRGB}, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.primaryRGB}, 0);
    }
  }

  @keyframes pulseWhite {
    0% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.whiteRGB}, 0.4);
    }
    70% {
      box-shadow: 0 0 0 2rem rgba(${props => props.theme.color.whiteRGB}, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.whiteRGB}, 0);
    }
  }

  @keyframes scrollPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.4);
    }
    70% {
      box-shadow: 0 0 0 1rem rgba(${props => props.theme.color.primaryRGB}, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${props => props.theme.color.primaryRGB}, 0);
    }
  }

  @keyframes preLoader {
    0% {
      border-width: 1rem;
    }
    25% {
      border-width: 0.3rem;
    }
    50% {
      border-width: 1rem;
      transform: rotate(115deg);
    }
    75% {
      border-width: 0.3rem;
    }
    100% {
      border-width: 1rem;
    }
  }

  @keyframes fadeOutDown {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes fadeInUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`

export default GlobalStyle