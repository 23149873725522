import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next, Link } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"
import scrollEventHandler from "../api/scrollEventHandler"
import ScrollTo from "../api/scrollTo"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const HeaderStyle = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 1030;
  
  grid-template-columns: [full-start] minmax(100px, 1fr)
  [center-start] minmax(1000px, 1120px) [center-end]
  minmax(100px, 1fr) [full-end];

  @media only screen and (${mediaQueries.tabLand}) {
    grid-template-columns: [full-start] minmax(50px, 1fr)
  [center-start] minmax(895px, 1100px) [center-end]
  minmax(50px, 1fr) [full-end];
  }

  @media only screen and (${mediaQueries.tabPort}) {
    grid-template-columns: [full-start] minmax(20px, 1fr)
  [center-start] minmax(724px, 950px) [center-end]
  minmax(20px, 1fr) [full-end];
    grid-row-gap: 6rem;
  }

  @media only screen and (${mediaQueries.phoneLand}) {
    grid-template-columns: [full-start] minmax(15px, 1fr)
  [center-start] minmax(280px, 740px) [center-end]
  minmax(15px, 1fr) [full-end];
  }

  &.header-shrink {
    border-bottom:none;
    padding: 0;
    background: ${props => props.theme.color.white};
    box-shadow: 0px 13px 35px -12px rgba(43, 50, 54, 0.2);
    animation: stickyMenu 0.7s ease-in-out;
    box-shadow: 0 5px 25px 0 rgba(${props => props.theme.color.backgroundDark}, 0.15);

    .navbar-logo{
      .logo{
        &-normal {
          display: inline-block !important;
        }

        &-transparent{
          display: none !important;
        }
      }
    }

    .nav-item {
      &:hover {
        .nav-link {
          color: ${props => props.theme.color.primary};
        }
      }

      .nav-link {
        color: ${props => props.theme.color.black};
        &.active, &:hover{
          color: ${props => props.theme.color.primary};
        }
        &:after {
          background: ${props => props.theme.color.primary};
        }
        &:not(.active) {
          &:after {
            background: ${props => props.theme.color.primary};
          }
        }
      }
    }
  }
`

const NavBar = styled.nav`
  grid-column: center-start / center-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .navbar-logo {
    padding: 0;
    margin-right: 0;

    .logo {
      &-normal {
        display: none !important;
      }
    }
  }
`

const NavBarMenu = styled.ul`
  display: flex;
`

const NavItem = styled.li`
  .nav-link {
    border: none;
    appearance: none;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.text};
    cursor: pointer;
    border-radius: 0;
    color: ${props => props.theme.color.white};
    font-size: 1.2rem;
    font-weight: ${props => props.theme.weight.robotoSemiBold};
    padding: 2.8rem 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    display: flex;
    position: relative;
    line-height: 2.8rem;
    justify-content: center;
    align-items: center;
    margin: 0;

    .nav-link-icon {
      margin-right: 0.7rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 7px;
      background: ${props => props.theme.color.primary};
    }

    &:not(.active) {
      &:after {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 7px;
        z-index: 7;
        content: '';
        position: absolute;
        transform: scaleX(0);
        transform-origin: 100% 0;
        background: ${props => props.theme.color.primary};
        transition: transform 0.4s ease;
      }

      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: 0 0;
        }
      }
    }
  }

  &:hover {
    > a {
      color: ${props => props.theme.color.primary};
    }
  }
`

const Header = ({ page = "" }) => {
  const { t } = useI18next()
  const data = useStaticQuery(graphql`
      query {
          logoBlack: file(relativePath: { eq: "logo-teicno.png" }) {
              childImageSharp {
                  fixed(width: 75) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
          logoWhite: file(relativePath: { eq: "logo-teicno-white.png" }) {
              childImageSharp {
                  fixed(width: 75) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  useEffect(() => {
    window.addEventListener("scroll", () => scrollEventHandler())
    return () => {
      window.removeEventListener("scroll", () => scrollEventHandler())
    }
  }, [])

  return (
    <HeaderStyle className={"header " + (page === "404" ? "header-shrink" : "")} id="header">
      <NavBar className="navbar">
        <Link to="/" className="navbar-logo" aria-label="Logo">
          <Img fixed={data.logoWhite.childImageSharp.fixed} alt="Logo" className="logo-transparent" />
          <Img fixed={data.logoBlack.childImageSharp.fixed} alt="Logo" className="logo-normal" />
        </Link>
        <NavBarMenu className="navbar-menu">
          <NavItem className="nav-item">
            <Link to="/process" className="nav-link menu-link" role="button">{t("header.link.process")}</Link>
          </NavItem>
          {
            page !== "404" ?
              <NavItem className="nav-item">
                <ScrollTo id="nav-contact-item" target={"contact"}
                          className={"nav-link menu-link"}>{t("header.link.contact")}</ScrollTo>
              </NavItem>
              :
              <></>
          }
        </NavBarMenu>
      </NavBar>
    </HeaderStyle>
  )
}

export default Header
